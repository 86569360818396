$(document).ready(function () {
    if ($('.sectionUpcomingEvents').length > 0) {
        $('.sectionUpcomingEvents').append('<div id="myModal" class="modal"><div class="modal-content"></div></div>')
    }
});

$(document).ajaxStop(function () {

    var unsubscribeText = 'U bent nu afgemeld voor deze vergadering.';
    var subscribeText = 'U bent nu aangemeld voor deze vergadering.';

    $('a[name^=workgroup_]').each(function () {
        $(this).on("click", function () {
            if ($(this).text() === 'Aanmelden' || ($(this).text() === 'Aanmelden...' )){
                $('.modal-content').text(subscribeText);
            }
            else if ($(this).text() === 'Afmelden' || $(this).text() === 'Afmelden...') {
                $('.modal-content').text(unsubscribeText);
            }
            $('.modal').show();
        });
    });

});